import * as THREE from "three";
import * as ZapparThree from '@zappar/zappar-threejs';

import Project from "../Project.js"; 

export default class Enviorment {
	constructor() {
        
		console.log( "Enviorment File" );
		// Instandiate 
		this.project = new Project();
		this.zappar = this.project.zappar;

		this.dirLight_intensity = 0.5;
		this.ambientLight_intensity = 1.5;
		// this.setZapparEnviormentMap();
		this.setEnviormentMap( this.project.resources.items.hdri_image );
		this.setLightFormer();
		this.setDirectionLight();
		// this.setFillLight();
		// this.setBackLight();
		// this.setAmbientLight()
	}
	/* setZapparEnviormentMap() {
		// Set up the real time environment map From Zappar
		this.environmentMap = new ZapparThree.CameraEnvironmentMap();
		this.project.scene.environment = this.environmentMap.environmentMap;
	} */
	setEnviormentMap( hdriImage ) {
		const enviormentMap = hdriImage;
		enviormentMap.mapping = THREE.EquirectangularReflectionMapping;
		this.project.scene.environment = enviormentMap;
		this.project.scene.environmentIntensity = 1;
		this.project.scene.environmentRotation = new THREE.Vector3( 0, 4.23, 0 );
	}
	setLightFormer() {
		const rectLight = new THREE.RectAreaLight( 0xffffff, 6, 3, 5 ); // (color, intensity, width, height)
		rectLight.position.set( 1.05, 2, 3 );
		rectLight.lookAt( 0, 0, 0 ); // Make the light face the origin
		this.project.scene.add( rectLight );
	}
	setDirectionLight() {
		// Direction light
		this.dirLight = new THREE.DirectionalLight(	"#FAFEFF", 1 );
		// this.dirLight.position.set(2, 8.5, 6)
		this.dirLight.position.set( 1.05, 2.33, 3 );
		this.dirLight.intensity = this.dirLight_intensity;
		this.dirLight.castShadow = true;
		this.dirLight.shadow.bias = -0.004;
        
		// Configure shadow resolution (higher = better quality)
		this.dirLight.shadow.mapSize.width = 1024;
		this.dirLight.shadow.mapSize.height = 1024;

		// Set shadow camera properties. By default, the light is a point source, so shadows are sharp. To soften them, increase the size of the shadow camera
		this.shadowDistance = 2;
		this.dirLight.shadow.camera.top = this.shadowDistance;
		this.dirLight.shadow.camera.bottom = -this.shadowDistance;
		this.dirLight.shadow.camera.left = -this.shadowDistance;
		this.dirLight.shadow.camera.right = this.shadowDistance;
		this.dirLight.shadow.camera.near = 0.5;
		this.dirLight.shadow.camera.far = 10;
		this.dirLight.shadow.radius = 10; // Higher values = softer shadows
        
		// Light target postion object
		this.targetObject = new THREE.Object3D(); 
		this.dirLight.target = this.targetObject; 

		this.zappar.instantTrackerGroup.add( this.dirLight );
		this.zappar.instantTrackerGroup.add( this.targetObject );
        
		/* this.zappar.instantTrackerGroup.add( new THREE.CameraHelper( this.dirLight.shadow.camera ) ) */
	}
	setFillLight() {
		this.fillLight = new THREE.DirectionalLight( "#FAFEFF", 2.5 );
		this.fillLight.position.set( -1.5, 2.2, 1.5 );
		this.fillLight.target = this.targetObject; 
		this.zappar.instantTrackerGroup.add( this.fillLight );
	}
	setBackLight() {
		this.backLight = new THREE.DirectionalLight( "#FAFEFF", 1 );
		this.backLight.position.set( -1.2, 1.5, -3 );
		this.backLight.target = this.targetObject; 
		//TODO CHANGE TO BACKLIGHT ADD
		this.zappar.instantTrackerGroup.add( this.fillLight );
	}
	setAmbientLight() {
		// Ambinet Light
		this.ambientLight = new THREE.AmbientLight( '#F4FDFF', this.ambientLight_intensity );
		this.zappar.instantTrackerGroup.add( this.ambientLight );
	}
	placementMode( bool ) {
		if( bool ) {
		}
		else if( !bool ) {
		}
	}
	update() {
		// this.environmentMap.update( this.project.renderer, this.project.camera );
	}
}