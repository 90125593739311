import * as THREE from 'three';
import { gsap } from 'gsap';

import Project from  "../Project.js"; 
import EventEmitter from '../utils/EventEmitter.js';

let equipmentModels, equipmentModelsMaterials, equipmentModelsParents;
let equipmentAllMaterials;
let activeTimeline;
export default class Equipment3D extends EventEmitter {
	constructor() {
		super();
		console.log( "Equipment3D" );
		this.project = new Project();
		this.zappar = this.project.zappar;
		this.world = this.project.world;
		this.resources = this.project.resources;
		this.time = this.project.time;
        
		this.resource = this.resources.items.equipment3D; // glb

		this.baseColour_01 = this.resources.items.equipment_01_baseColour; // texture
		this.normalMap_01 = this.resources.items.equipment_01_normalMap; // texture
		this.roughnessMap_01 = this.resources.items.equipment_01_roughnessMap; // texture
		this.metallicMap_01 = this.resources.items.equipment_01_metallicMap; // texture
		this.aoMap_01 = this.resources.items.equipment_01_aoMap; // texture
        
		this.baseColour_02 = this.resources.items.equipment_02_baseColour; // texture
		this.normalMap_02 = this.resources.items.equipment_02_normalMap; // texture
		this.roughnessMap_02 = this.resources.items.equipment_02_roughnessMap; // texture
		this.metallicMap_02 = this.resources.items.equipment_02_metallicMap; // texture
		
		this.baseColour_03 = this.resources.items.equipment_03_baseColour; // texture
		this.normalMap_03 = this.resources.items.equipment_03_normalMap; // texture
		this.roughnessMap_03 = this.resources.items.equipment_03_roughnessMap; // texture
		// this.emissiveMap_03 = this.resources.items.equipment_03_emissiveMap; // texture
        
		equipmentAllMaterials = [ this.baseColour_01, this.normalMap_01, this.roughnessMap_01, this.metallicMap_01, this.aoMap_01, this.baseColour_02, this.normalMap_02, this.roughnessMap_02, this.metallicMap_02, this.baseColour_03, this.normalMap_03, this.roughnessMap_03 ];
		this.setModel();
	}
	setModel() {
		this.model = this.resource.scene; //glb
        
		// Ensure that the meshes cast shadows.
		this.model.traverse( ( child ) => {
			if ( child.isMesh ) { 
				child.castShadow = true; 
				child.receiveShadow = false;
				child.material.vertexColors = false;
			}
		} );
		// get refernces on model    
		equipmentModels = 
        {
        	all : this.model,
        	devices :
            {
            	all : this.model.getObjectByName( "M_Devices" ),
            	phone : this.model.getObjectByName( "M_Phone_LO" ),
            	tablet : this.model.getObjectByName( "M_Tablet_LO" ),
            	tv : this.model.getObjectByName( "M_TV_LO" ),
            }, 
        	equipment :
            {
            	all : this.model.getObjectByName( "M_Equipment" ),
            	eye : 
                {
                	all : this.model.getObjectByName( "M_Eye" ),
                	left : this.model.getObjectByName( "Eyeball_L" ),
                	middle : this.model.getObjectByName( "Middle_Fill" ),
                	right : this.model.getObjectByName( "Eyeball_R" ),
                	cornea_L : this.model.getObjectByName( "Cornea_L" ),
                	cornea_R : this.model.getObjectByName( "Cornea_R" ),
                },
            	octScanner : this.model.getObjectByName( "Optos_Scanner" ),
            	phoropter : 
                {
                	all : this.model.getObjectByName( "Phoropter_V2" ),
                	glass : this.model.getObjectByName( "glass" ),
                },
            	snellenChart : this.model.getObjectByName( "M_Snellen_Chart" ),
            },
        	eyeLeftVisible ( visible ) {
        		this.equipment.eye.left.visible = visible;
        	},
        	eyeRightVisible ( visible ) {
        		this.equipment.eye.right.visible = visible;
        	},
        	eyeMiddleVisible( visible ) {
        		this.equipment.eye.middle.visible = visible;
        	}
        };
		equipmentModelsMaterials = 
        {
        	materials_01 : 
            [
            	equipmentModels.devices.phone.children[0],
            	equipmentModels.devices.tablet.children[0],
            	equipmentModels.devices.tv.children[0],
            	equipmentModels.equipment.snellenChart.children[1],
            	// equipmentModels.equipment.octScanner.children[0],
            	equipmentModels.equipment.phoropter.all,
            ],
        	eyeMaterial : [
        		equipmentModels.equipment.eye.left,
        		equipmentModels.equipment.eye.right,
        		equipmentModels.equipment.eye.middle.children[0], 
        	],
        	materials_02 : 
            [
            	equipmentModels.devices.phone.children[1],
            	equipmentModels.devices.tablet.children[1],
            	equipmentModels.devices.tv.children[1],
            	equipmentModels.equipment.snellenChart.children[0],
            	// equipmentModels.equipment.octScanner.children[1],
            ],
        };
		equipmentModelsParents = 
        [
        	equipmentModels.devices.phone, equipmentModels.devices.tablet, equipmentModels.devices.tv,
        	equipmentModels.equipment.eye.all, equipmentModels.equipment.octScanner, equipmentModels.equipment.phoropter.all,
        	equipmentModels.equipment.snellenChart,
        ];
		this.setModelMaterials();
		setTimeout( () => {
			this.setModelsScaleAndRotation();
			this.setModelPositions();
			equipmentModels.eyeMiddleVisible( false );
		}, 0 );
		this.zappar.instantTrackerGroup.add( this.model );
	}
	setModelMaterials() {
		// Create placement material
		this.opacityMat = new THREE.MeshBasicMaterial(
			{
				color : "grey",
				transparent : true,
				opacity : 0.7
			} );
        
		// Create Glass material
		this.glassMat = new THREE.MeshPhysicalMaterial(
			{
				color : "#e2e2e2",
				roughness : 0, 
				metalness : 0.05,
				transmission : 1,
				ior : 2.33,
				envMapIntensity : 0.95,
				thickness : 0.2,
				side : -1,
			} );
		// Create a material for Equipment
		this.equipmentModels_01_Material = new THREE.MeshStandardMaterial(
			{
				map : this.baseColour_01,
				roughnessMap : this.roughnessMap_01,
				metalnessMap : this.metallicMap_01,
				normalMap : this.normalMap_01,
				aoMap : this.aoMap_01,
				envMapIntensity : 0.5,
				toneMapped : false,
			}
		);
		this.equipmentModels_02_Material = new THREE.MeshStandardMaterial(
			{
				map : this.baseColour_02,
				roughnessMap : this.roughnessMap_02,
				metalnessMap : this.metallicMap_02,
				normalMap : this.normalMap_02,
				envMapIntensity : 0.6,
				toneMapped : false,
			}
		);
		this.equipmentModels_03_Material = new THREE.MeshStandardMaterial(
			{
				map : this.baseColour_03,
				roughnessMap : this.roughnessMap_03,
				metalness : 0,
				normalMap : this.normalMap_03,
				envMapIntensity : 0.5,
				toneMapped : false,
			}
		);
		// Clone material 01 (which has eye mat)
		this.eyeMaterial = this.equipmentModels_01_Material.clone();
		// bump up it is Env map intensity
		this.eyeMaterial.envMapIntensity = 2;
		this.eyeMaterial.toneMapped = true;
		equipmentAllMaterials.map( ( texture ) => {
			texture.flipY = false; // needed due to GLB files
		} );
		this.baseColour_01.colorSpace = THREE.SRGBColorSpace;

		// Set the materails for the models
		equipmentModels.equipment.eye.cornea_L.material = this.glassMat;
		equipmentModels.equipment.eye.cornea_R.material = this.glassMat;
		equipmentModels.equipment.eye.middle.children[1].material = this.glassMat;
		equipmentModels.equipment.phoropter.glass.material = this.glassMat;

		equipmentModels.equipment.octScanner.material = this.equipmentModels_03_Material;
		// Go through all equipment models and set their texture
		equipmentModelsMaterials.materials_01.map( ( indvMaterial ) => {
			indvMaterial.material = this.equipmentModels_01_Material;
		} );
		equipmentModelsMaterials.eyeMaterial.map( ( indvMaterial ) => {
			indvMaterial.material = this.eyeMaterial;
		} );
		equipmentModelsMaterials.materials_02.map( ( indvMaterial ) => {
			indvMaterial.material = this.equipmentModels_02_Material;
		} );
	}
	setModelPositions() {
		equipmentModels.all.position.set( 0, 1.5, -0.25 );
		// Devices        
		equipmentModels.devices.tablet.position.set( -0.50, 0, 0 );
		equipmentModels.devices.tablet.rotation.set( 0, 0, 0.15 );
		equipmentModels.devices.phone.position.set( 0.45, 0, 0 );
		equipmentModels.devices.phone.rotation.set( 0, 0, -0.15 );
		equipmentModels.devices.tv.position.set( 0, 0.5, 0 );
		equipmentModels.devices.tv.rotation.set( 0, 0, -0.07 );
        
		// Equipment - Smellen & phoropter
		equipmentModels.equipment.snellenChart.position.set( -0.55, 0.3, 0 );
		equipmentModels.equipment.snellenChart.rotation.set( 0, 0, 0.15 );
		equipmentModels.equipment.phoropter.all.position.set( 0.55, 0.3, 0 );
		equipmentModels.equipment.phoropter.all.rotation.set( 0, 0, -0.15 );
		equipmentModels.equipment.eye.all.position.set( 0.55, 0.25, -0.1 );
        
		// Equipment - OCT Scanner
		equipmentModels.equipment.octScanner.position.set( -0.7, 0.3, -0.2 );
		equipmentModels.equipment.octScanner.rotation.set( 0.4, 0, 0 );
	}
	setModelsScaleAndRotation() {
		equipmentModelsParents.map( ( indvModel ) => {
			indvModel.scale.set( 0, 0, 0 );
			indvModel.rotation.set( 0, 0, 0 );
		} );
      
	}
	devicesAnimationTimeline() {
		// Create GSAP Timeline with called back        
		this.part1AnimTL = gsap.timeline( {
			defaults : { 
				repeat : 0,
				ease : "back.out",
				duration : 1,
			}, 
			onComplete : () => {
				this.deactiavteTimeline();
			}
		} );
		activeTimeline = this.part1AnimTL;
		this.part1AnimTL.to( equipmentModels.devices.phone.scale, { x : 1, y : 1, z : 1 }, 10 )
			.add( () => {
				this.project.uiSoundEffect( this.project.arcadeGamePop_audio ); 
			}, 10 )
			.to( equipmentModels.devices.tablet.scale, { x : 1, y : 1, z : 1 }, 10.5 )
			.add( () => {
				this.project.uiSoundEffect( this.project.arcadeGamePop_audio ); 
			}, 10.5 )
			.to( equipmentModels.devices.tv.scale, { x : 1, y : 1, z : 1 }, 11 )
			.add( () => {
				this.project.uiSoundEffect( this.project.arcadeGamePop_audio ); 
			}, 11 )
		// Devices Idle anim
			.to( equipmentModels.devices.phone.rotation, {
				y : -0.35,
				duration : 4,
				ease : "sine.inOut"
			}, 11 )
			.to( equipmentModels.devices.tablet.rotation, {
				y : 0.35,
				duration : 4,
				ease : "sine.inOut"
			}, 11.5 )
			.to( equipmentModels.devices.tv.rotation, {
				y : -0.35,
				duration : 4,
				ease : "sine.inOut"
			}, 12 )

		// Devices Idle anim loop
			.to( equipmentModels.devices.phone.rotation, {
				y : 0.35,
				duration : 8,
				repeat : 2,
				yoyo : true,
				ease : "sine.inOut"
			}, 15 )
			.to( equipmentModels.devices.tablet.rotation, {
				y : -0.35,
				duration : 8,
				repeat : 2,
				yoyo : true,
				ease : "sine.inOut"
			}, 15.5 )
			.to( equipmentModels.devices.tv.rotation, {
				y : 0.35,
				duration : 8,
				repeat : 2,
				yoyo : true,
				ease : "sine.inOut"
			}, 15 )
        
		// Devices Disappear 1 by 1
			.to( equipmentModels.devices.tv.scale, { duration : 1, ease : "back.in", x : 0, y : 0, z : 0 }, 22 )
			.to( equipmentModels.devices.tablet.scale, { duration : 1, ease : "back.in", x : 0, y : 0, z : 0 }, 22.5 )
			.to( equipmentModels.devices.phone.scale, { duration : 1, ease : "back.in", x : 0, y : 0, z : 0 }, 23 )

		// Equipment 
		//Smellen & phoropter models appear
			.to( equipmentModels.equipment.snellenChart.scale, { x : 1, y : 1, z : 1 }, 37 )
			.add( () => {
				this.project.uiSoundEffect( this.project.arcadeGamePop_audio ); 
			}, 37 )
			.to( equipmentModels.equipment.phoropter.all.scale, { x : 1, y : 1, z : 1 }, 37.5 )
			.add( () => {
				this.project.uiSoundEffect( this.project.arcadeGamePop_audio ); 
			}, 37.5 )
		// Smellen & phoropter Idle anaim
			.to( equipmentModels.equipment.snellenChart.rotation, {
				y : 0.35,
				duration : 4,
				ease : "sine.inOut"
			}, 38 )
			.to( equipmentModels.equipment.phoropter.all.rotation, {
				y : -0.35,
				duration : 4,
				ease : "sine.inOut"
			}, 38.5 )

		// Smellen & phoropter Idle anaim loop
			.to( equipmentModels.equipment.snellenChart.rotation, {
				y : -0.35,
				duration : 8,
				repeat : 0,
				yoyo : true,
				ease : "sine.inOut"
			}, 42 )
			.to( equipmentModels.equipment.phoropter.all.rotation, {
				y : 0.35,
				duration : 8,
				repeat : 0,
				yoyo : true,
				ease : "sine.inOut"
			}, 42.5 )

		// Smellen & phoropter disappear 1 by 1
			.to( equipmentModels.equipment.phoropter.all.scale, { duration : 1, ease : "back.in", x : 0, y : 0, z : 0 }, 43 )
			.to( equipmentModels.equipment.snellenChart.scale, { duration : 1, ease : "back.in", x : 0, y : 0, z : 0 }, 44.5 )

		// Eyeball 
			.to( equipmentModels.equipment.eye.all.scale, { x : 1, y : 1, z : 1 }, 48 )
			.add( () => {
				equipmentModels.equipment.eye.all.position.x = -0.55;
				this.project.uiSoundEffect( this.project.arcadeGamePop_audio ); 
			}, 48 )
			.to( equipmentModels.equipment.octScanner.scale, { x : 1, y : 1, z : 1 }, 48.5 )
			.add( () => {
				equipmentModels.equipment.octScanner.position.x = 0.65;
				this.project.uiSoundEffect( this.project.arcadeGamePop_audio ); 
			}, 48.5 )
			.to( equipmentModels.equipment.octScanner.rotation, {
				y : -0.2,
				duration : 3.5,
				repeat : 1,
				yoyo : true,
				ease : "sine.inOut"
			}, 48.5 )
			.to( equipmentModels.equipment.eye.all.rotation, {
				y : -0.8,
				duration : 3,
				repeat : 0,
				ease : "sine.inOut"
			}, 49 )
			.to( equipmentModels.equipment.eye.all.rotation, {
				y : -1,
				duration : 3,
				repeat : 3,
				yoyo : true,
				ease : "sine.inOut"
			}, 52 )
			.add( () => {
				equipmentModels.eyeRightVisible( false ); 
				equipmentModels.eyeMiddleVisible( true ); 
			}, 54 )
			.to( equipmentModels.equipment.octScanner.rotation, {
				y : 0.2,
				duration : 3.5,
				repeat : 1,
				yoyo : true,
				ease : "sine.inOut"
			}, 55.5 )

		// Eye disappear 
			.to( equipmentModels.equipment.eye.all.scale, { duration : 1, ease : "back.in", x : 0, y : 0, z : 0 }, 59 )
			.to( equipmentModels.equipment.octScanner.scale, { duration : 1, ease : "back.in", x : 0, y : 0, z : 0 }, 60.5 );
	}
	octScanAnimationTimeline() {
		console.log( "Learn more about OCT" );
		equipmentModels.equipment.eye.all.position.set( -0.6, 0.3, 0 );
		this.octScanTL = gsap.timeline( {
			defaults : { 
				repeat : 0,
				ease : "back.out",
				duration : 1,
			},
			onComplete : () => {
				this.deactiavteTimeline();
			}
		} );
		activeTimeline = this.octScanTL;
		this.octScanTL.to( equipmentModels.equipment.octScanner.scale, { x : 0.8, y : 0.8, z : 0.8 }, 6.5 )
			.add( () => {
				equipmentModels.equipment.octScanner.position.x = 0.55;
				this.project.uiSoundEffect( this.project.arcadeGamePop_audio ); 
			}, 6.5 )
			.to( equipmentModels.equipment.eye.all.scale, { x : 1, y : 1, z : 1 }, 7.5 )
			.add( () => {
				equipmentModels.equipment.eye.all.position.x = -0.6;
				this.project.uiSoundEffect( this.project.arcadeGamePop_audio ); 
			}, 7 )
		// Equipment Idle anim
			.to( equipmentModels.equipment.octScanner.rotation, {
				y : -0.9,
				duration : 6,
				repeat : 1,
				yoyo : true,
				ease : "sine.inOut"
			}, 7.5 )
			.to( equipmentModels.equipment.eye.all.rotation, {
				y : -1.2,
				duration : 5,
				ease : "sine.inOut",
				repeat : 1,
				yoyo : true,
			}, 8 )
			.add( ()=> {
				console.log( "End" );
			}, 20 )
		/* .add( () => {
				equipmentModels.eyeRightVisible( false ); 
				equipmentModels.eyeMiddleVisible( true ); 
			}, 25 ) */
		// Equipment Idle anim Loop
			.to( equipmentModels.equipment.eye.all.rotation, {
				y : -1,
				duration : 7,
				yoyo : true,
				repeat : 1,
				ease : "sine.inOut"
			}, 18 )
			.to( equipmentModels.equipment.octScanner.rotation, {
				y : -0.7,
				duration : 6.5,
				yoyo : true,
				repeat : 1,
				ease : "sine.inOut"
			}, 19.5 )
			.to( equipmentModels.equipment.eye.all.scale, { duration : 1, ease : "back.in", x : 0, y : 0, z : 0 }, 25 )
			.to( equipmentModels.equipment.octScanner.scale, { duration : 1, ease : "back.in", x : 0, y : 0, z : 0 }, 26 );
			
	}
	TogglePlacementMaterial( bool ) {
		const mat = bool ? this.opacityMat : this.equipmentMaterial;
		billieModel.forEach( ( element ) => {
			element.material = mat;
		} );
	}
	// ANIMATION TIMELINES
	deactiavteTimeline() {
		if( !activeTimeline ) return; 

		activeTimeline.kill();
		if( activeTimeline === this.part1AnimTL ) {
			// equipmentModels.part1ModelsVisible( false )
			equipmentModels.eyeRightVisible( true );
			equipmentModels.eyeMiddleVisible( false );
		}
		else if( activeTimeline === this.octScanTL ) {
			equipmentModels.eyeRightVisible( true );
			equipmentModels.eyeMiddleVisible( false );
		}
		activeTimeline = undefined;
		this.setModelsScaleAndRotation();
		this.setModelPositions();
	}
	pauseTimelines() {
		if ( activeTimeline ) activeTimeline.pause();
	}
	resumeTimelines() {
		if( activeTimeline ) {
			// activeTimeline.time(timeValue)
			activeTimeline.resume();
		}
	}
	placementMode( bool ) {
		if( bool ) {
			// Pause Timelines
			this.pauseTimelines();
			// this.TogglePlacementMaterial(true)
		}
		else if( !bool ) {
			if( this.project.initalPlacement ) {
				this.project.initalPlacement = false;
				this.devicesAnimationTimeline();
			}
			this.resumeTimelines();
			// this.TogglePlacementMaterial(false)
		}
	}
	update() {
	}
}