const gtagID = 'G-KLMGV7NZBK'; // Boots GA Tag TODO Comment back in
// const gtagID = 'G-KJFKX7STGC'; // MY GA Tag
let websiteDomian =  document.domain;

export default class GoogleAnalytics {
	constructor() {
		console.log( "Google Analytics" );
		window.dataLayer = window.dataLayer || [];
		this.gtag( 'consent', 'default', { 'analytics_storage' : 'denied' } );
		// Check if has GA cookie on the page
		if( document.cookie.length > 0 ) {
			this.optOut();
		}  
	}
	gtag() { 
		dataLayer.push( arguments ); 
	}
	event( eventName ) {
		if( document.getElementById( "google-analytics" ) ) {
			this.gtag( "event", eventName );
			console.log( `event = ${eventName}` );
		}
	}
	optIn() {
		let gaScript = document.createElement( "script" );
		gaScript.id = 'google-analytics';
		gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${ gtagID }`;
		document.head.appendChild( gaScript );
		
		// Update cookie conset to allow analytical cookies
		this.gtag( 'consent', 'update', { 'analytics_storage' : 'granted' } );

		this.gtag( 'js', new Date() );
		this.gtag( 'config', gtagID );
		if( window.ga && ga.loaded ) {
			console.log( window.ga );
			console.log( "window.ga" );
		}
	}
	optOut() {
		const allCookies = document.cookie.split( ";" );
		websiteDomian = websiteDomian.substring( websiteDomian.indexOf( "." )+1 );
		for ( let i = 0; i < allCookies.length; i++ ) {
			document.cookie = allCookies[ i ] + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; domain=" + websiteDomian + "; path=/";
			// console.log( document.cookie.indexOf( allCookies[ i ] ) ); 
		}
	}
}