import * as THREE from "three";
import Project from "../Project.js";

export default class Floor {
	constructor( planeSizeX, planeSizeY ) {
		console.log( "Floor File" );
		this.project = new Project();
		this.zappar = this.project.zappar;

		this.planeSizeX = planeSizeX;
		this.planeSizeY = planeSizeY;
            
		this.setShadowPlane();
	}
	setShadowPlane() {
		// Create a plane which will receive the shadows.
		this.shadowPlane = new THREE.Mesh(
			new THREE.PlaneGeometry( this.planeSizeX, this.planeSizeY ),
			// new THREE.MeshStandardMaterial( { color : "red" } ),
			new THREE.ShadowMaterial( { opacity : 0.4 } ),
		);
		this.shadowPlane.position.set( 0, 0, -1.2 );
		this.shadowPlane.receiveShadow = true;
		this.shadowPlane.rotation.x = -Math.PI / 2;
       
		this.zappar.instantTrackerGroup.add( this.shadowPlane );
	}
	update() {

	}
}

