import * as THREE from 'three';
import { gsap } from 'gsap';

import Project from "../Project.js";
import Enviorment from './Enviorment.js';
import Floor from './Floor.js';
import Asset3D from './Asset3D.js';
import Equipment3D from './Equipment3D.js';

let pulseElement;
export default class World {
	constructor() {
		console.log( "World File" );
		this.project = new Project();
		this.zappar = this.project.zappar;
		this.scene = this.project.scene;
		this.resources = this.project.resources;
        
		this.resources.on( "ready", () => {
			// Setup
			this.enviorment = new Enviorment();
			this.plane = new Floor( 4, 7 );
			this.asset3D = new Asset3D();
			this.equipment3D = new Equipment3D();
            
			this.placement_indicator_texture = this.resources.items.placement_indicator_texture;
			this.setInstance();
			this.placement_Indicator_initialise();
		} );
	}
	setInstance() {      
		this.setVoiceAudioClip( "part1" );
		this.asset3D.setInstance();
	}
	placement_Indicator_initialise() {

		this.placement_indicator_mesh = new THREE.Mesh(
			new THREE.CircleGeometry( 0.4, 32 ),
			new THREE.MeshBasicMaterial( { color : new THREE.Color( "rgb(5, 5, 75)" ), } ),
		);
		this.zappar.instantTrackerGroup.add( this.placement_indicator_mesh );
		this.placement_indicator_mesh.scale.set( 0, 0, 1 );
		this.placement_indicator_mesh.position.y = 0.01;
		this.placement_indicator_mesh.rotation.x = - Math.PI / 2;
	}
	setVoiceAudioClip( part ) {
		if( !this.project.billieVo_part01 ) return;
		switch ( part ) {
		case "part1":
			this.currentVoiceAudioCLip = this.project.BillieVO_Part1_Audio;
			this.currentVoiceAudioCLip.offset = 0;
                
			break;
                
		case "idle":
			this.currentVoiceAudioCLip = undefined;
			break;
                
		case "part2":
			this.currentVoiceAudioCLip = this.project.BillieVO_Part2_Audio;
			this.currentVoiceAudioCLip.offset = 0.00;
			break;
		}
	}
	learnMoreOCTScans() {
		//Equipment3D 
		this.equipment3D.deactiavteTimeline();
		this.stopVoiceAuido();
		this.setVoiceAudioClip( "part2" );
		this.pulseTimelineStop();
        
		setTimeout( () => {
			this.asset3D.crossFadeAnimationsClips();
			this.equipment3D.octScanAnimationTimeline();
			this.asset3D.pauseAllAnimations( false );
			this.playVoiceAudio( true );
		}, 10 );
	}
	playVoiceAudio( play ) {
		if ( !this.currentVoiceAudioCLip ) return;
		play ? this.currentVoiceAudioCLip.play() : this.currentVoiceAudioCLip.stop();
	}
	stopVoiceAuido() {
		if ( !this.currentVoiceAudioCLip ) return;
		this.currentVoiceAudioCLip.stop();
		// this.currentVoiceAudioCLip.currentTime = 0;
		this.currentVoiceAudioCLip = undefined;
	}
	stopAllAnimations() {
		this.asset3D.deactivateAllAnimations();
		this.equipment3D.deactiavteTimeline();
		this.stopVoiceAuido();
	}
	idelActions() {
		pulseElement = gsap.timeline( { 
			defaults : {
				repeat : -1,
				duration : 2,
				ease : "power1.in",
				yoyo : true
			} 
		} );
		console.log( "Pulsing" );
		pulseElement.to( this.project.html.gameplay.bookEyeTest_btn, { scale : 1.1, duration : 2 }, 0 );		
	}
	pulseTimelineStop() {
		if( !pulseElement ) return;
		gsap.to( this.project.html.gameplay.bookEyeTest_btn, { scale : 1, duration : 0.6 } );
		pulseElement.pause();
		pulseElement.kill();
	}
	placementMode( bool ) {
		if( bool ) {
			this.project.hasPlaced = false;
			if ( !this.project.initalPlacement ) {
				this.asset3D.resumeAnimationClip();
				this.playVoiceAudio( false );
			}
			this.asset3D.placementMode( true );
			this.equipment3D.placementMode( true );
			gsap.to( this.placement_indicator_mesh.scale, { x : 1, y : 1, duration : 0.4 } );
			this.placement_indicator_mesh.visible = true;
		}
		else if( !bool ) {
			gsap.to( this.placement_indicator_mesh.scale, {
				x : 0,
				y : 0, 
				duration : 0.4, 
				onComplete : () => { 
					this.placement_indicator_mesh.visible = false; 
					this.playVoiceAudio( true );
					this.project.hasPlaced = true;
					this.asset3D.placementMode( false );
					this.equipment3D.placementMode( false );
				}
			} );
		}
	}
	update() {
		if( this.project.hasPlaced ) {    
			if( this.asset3D ) {
				this.asset3D.update();
			}
			// console.log("audio = " + this.currentVoiceAudioCLip.currentTime.toFixed(2))
			// console.log("anaimtion = " + this.asset3D.currentAnimClip.time.toFixed(2))
		}
		if( this.project.camera ) {
			this.enviorment?.update();
		}
	}
}   

